import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Seo from "../../components/seo";

import Header from "../../components/header";
import Newsletter from "../../components/newsletter";
import Footer from "../../components/footer";
import ContainerDefault from "../../components/container";
import Breadcrumbs from "../../components/breadcrumbs";
import ProjectCard from "../../components/project-card";

import api from "../../services/api";

const Container = styled.div`
  text-align: center;
  padding: 35px 0;

  h2 {
    font: bold italic 36px "Times new roman";
  }

  .projects-list {
    li {
      margin-bottom: 35px;
    }
  }

  @media (min-width: 767px) {
    padding: 45px 0;

    .projects-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 8px;
      grid-row-gap: 22px;

      li {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 1200px) {
    padding: 45px 0 150px;

    .projects-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 28px;
    }
  }
`;

export default function Search(props) {
  const results = useStaticQuery(graphql`
    {
      seo: prismicSeoPaginaBuscaMeuAmbiente {
        data {
          canonical {
            url
          }
          meta_description
          meta_og_description
          meta_og_image {
            url
          }
          meta_og_title
          meta_og_type
          meta_og_url {
            url
          }
          meta_title
        }
      }
    }
  `);

  const { seo } = results;

  const [term, setTerm] = useState("");
  const [projects, setProjects] = useState([]);
  let params = "";

  useEffect(() => {
    const params = window.location.search.split("=")[1].replace("%20", " ");
    setTerm(params);

    async function searchAPI(params) {
      const response = await api.get(
        `https://apimeuambiente.guararapes.com.br/project/search/${params}`
      );
      setProjects(response.data);
    }

    searchAPI(params);
  }, [params]);

  return (
    <>
      {seo && (
        <Seo
          metaTitle={seo.data.meta_title}
          metaDescription={seo.data.meta_description}
          title={seo.meta_og_title}
          description={seo.meta_og_description}
          image={seo.meta_og_image ? seo.meta_og_image.url : ""}
          url={seo.data.meta_og_url ? seo.data.meta_og_url : ""}
          type={seo.meta_og_type}
        />
      )}
      <Header />
      <Breadcrumbs term={decodeURI(term)} numberOfItems={projects.length} />
      <Container>
        <ContainerDefault>
          {projects.length > 0 && (
            <ul className="projects-list">
              {projects.map((project, index) => (
                <li key={index}>
                  <ProjectCard
                    id={project.id}
                    image={project.gallery}
                    name={project.name}
                    city={project.city}
                    uf={project.uf}
                    authorImage={
                      project.user.company_avatar !== null
                        ? project.user.company_avatar
                        : "/images/01.png"
                    }
                    authorName={project.user.company_name}
                    numberOfLikes={project.likes}
                    products={project.products}
                  />
                </li>
              ))}
            </ul>
          )}
        </ContainerDefault>
      </Container>
      <Newsletter />
      <Footer />
    </>
  );
}
