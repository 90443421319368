import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import ContainerDefault from '../container';

const Container = styled.div`
  background: #ffffff;
  padding-top: 35px;
  padding-bottom: 40px;

  .breadcrumb-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    ul {
      display: flex;
      align-items: center;

      li {
        color: #40403f;
        font-size: 13px;
        letter-spacing: 0.29px;

        a {
          color: #40403f;
        }

        &::after {
          content: '>';
          margin: 0 5px;
          display: inline-block;
        }

        &:last-child::after {
          display: none
        }
      }
    }

    .back-home {
      display: flex;
      align-items: center;

      span {
        font-weight: 600;
        color: #40403f;
        font-size: 13px;
        letter-spacing: 0.29px;
      }

      i {
        background: url('/images/arrow-left.png') center no-repeat;
        background-size: contain;
        margin-right: 10px;
        width: 15px;
        height: 11px;
      }
    }
  }

  .breadcrumb-term {
    font: italic bold 27px 'Times new roman';
    color: #000000;
    margin-bottom: 20px;
  }

  .breadcrumb-found {
    font-size: 13px;
    color: #40403f;
    margin-bottom: 0;
  }

  @media (min-width: 767px) {
    padding-top: 40px;
    padding-bottom: 30px;

    .breadcrumb-header {
      ul li {
        font-size: 14px;
      }

      .back-home span {
        font-size: 14px;
      }
    }

    .breadcrumb-term {
      font: italic bold 40px 'Times new roman';
      margin-bottom: 30px;
    }

    .breadcrumb-found {
      font-size: 16px;
    }
  }

  @media (min-width: 1200px) {
    padding-top: 26px;

    .breadcrumb-term {
      font: italic bold 45px 'Times new roman';
    }
  }
`;

export default function Breadcrumbs({ term, numberOfItems }) {
  return (
    <Container>
      <ContainerDefault>
        <div className="breadcrumb-header">
          <ul>
            <li>
              <Link to="/">Início</Link>
            </li>
            <li>Busca</li>
          </ul>

          <Link to="/" className="back-home">
            <i></i>
            <span>Voltar para Página Inicial</span>
          </Link>
        </div>

        <h1 className="breadcrumb-term">Buscando por: “{term}”</h1>
        <p className="breadcrumb-found">
          {
            numberOfItems  === 0 || numberOfItems === 1
            ? `${numberOfItems} projeto encontrado`
            : `${numberOfItems} projetos encontrados`
          }
        </p>
      </ContainerDefault>
    </Container>
  )
}